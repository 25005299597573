import introJs from 'intro.js'

$(function() {
  if (localStorage.getItem('firstVisit')!="1") {
    localStorage.setItem('firstVisit', '1');
    introJs().setOptions({
      nextLabel: 'suivant',
      prevLabel: 'précédent',
      steps: [{
        title: 'Bienvenue ! 👋',
        intro: '<i>à petite vitesse</i> est un service vous permettant de planifier vos étapes de voyage en itinérance'
      },
      {
        element: document.querySelector('#parameters'),
        title: 'Paramètres',
        intro: 'Renseignez vos paramètres ici'
      },
      {
        element: document.querySelector('#mapid'),
        title: 'Tracé',
        intro: 'Sélectionnez ou importez un tracé ici'
      }]
    }).start();
  }
});
